'use client'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AvailableTests, ExperimentsDto } from '../../types/experiment-dto'

type ExperimentsState = {
  experiments: ExperimentsDto[] | undefined
  lastSync: string | undefined
  setExperiments: (data: ExperimentsDto[]) => void
  getExperimentsBodyToRequest: () => ExperimentsDto[]
  shouldSync: () => boolean
}

export const useExperimentStore = create<ExperimentsState>()(
  persist(
    (set, get) => ({
      experiments: undefined,
      lastSync: undefined,
      setExperiments: (data) => {
        set({ experiments: data })
        set({ lastSync: new Date().toISOString() })
      },
      getExperimentsBodyToRequest: () => {
        const response: ExperimentsDto[] = []
        const storedExperiments = get().experiments

        Object.keys(AvailableTests).forEach((t) => {
          const expId: number = Number(t)

          if (expId) {
            response.push({
              exp: expId!,
              cId: storedExperiments?.find((e) => e.exp === expId)?.cId,
              var: storedExperiments?.find((e) => e.exp === expId)?.var,
            })
          }
        })

        return response
      },
      shouldSync: () => {
        const body = get().getExperimentsBodyToRequest()
        const storedLastSync = get().lastSync
        let lastSync: Date | undefined = undefined

        if (storedLastSync) {
          lastSync = new Date(storedLastSync)
        }

        return (
          body.length > 0 &&
          (body.some((b) => b.var === undefined) ||
            lastSync === undefined ||
            new Date().getTime() - lastSync.getTime() > 1000 * 60 * 60)
        )
      },
    }),
    {
      name: 'vari-store',
    }
  )
)
