'use client'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { TrackingProps } from '../actions/addTrk'

export type TrackingStore = {
  events: TrackingProps[]
  addEvent(event: TrackingProps): Promise<void>
  removeEvent(event: TrackingProps): void
  removeEvents(event: TrackingProps[]): void
  reset(): void
}

export const useTrackingStore = create<TrackingStore>()(
  persist<TrackingStore>(
    (set) => ({
      events: [],
      reset: () => {
        set({ events: [] })
      },
      removeEvent: (event: TrackingProps) => {
        set((state) => ({
          events: state.events.filter((e) => e !== event),
        }))
      },
      removeEvents: (events: TrackingProps[]) => {
        set((state) => ({
          events: state.events.filter((e) => !events.includes(e)),
        }))
      },
      addEvent: async (event: TrackingProps) => {
        set((state) => ({
          events: [...state.events, event],
        }))
      },
    }),
    {
      name: 'trk-st',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
