export enum AvailableTests {
  REMOVE_DAILY_GOAL = 1,
}

export enum Variant {
  ORIGINAL_VERSION_METRICS_IGNORED = 0,
  ORIGINAL_VERSION = 1,
  V2 = 2,
  V3 = 3,
  V4 = 4,
}

export type ExperimentsDto = {
  exp: number
  cId?: number
  var?: number
}
