export const validateTokenQuery = /* GraphQL */ `
  query validateTokenQuery($token: String!) {
    validateToken(token: $token) {
      isValid
      errorMessage
      decodedToken {
        id
        exp
        site
      }
      userInfo {
        display_name
      }
    }
  }
`
