import { createContext, useContext } from 'react'
import { UserSession } from '../types'

type SessionProviderContextState = {
  session?: {
    expires: Date
    user?: UserSession
  }
  isLoading: boolean
}

const SessionProviderContext = createContext<SessionProviderContextState>({
  session: undefined,
  isLoading: false,
})

export const useSession = () => useContext(SessionProviderContext)

export const useSessionUser = () =>
  useContext(SessionProviderContext).session?.user

export function SessionResolver({ children, session, isLoading }) {
  return (
    <SessionProviderContext.Provider value={{ session, isLoading }}>
      {children}
    </SessionProviderContext.Provider>
  )
}
