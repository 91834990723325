import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import amplitude from 'amplitude-js'
import { TrackingEvent } from '@/types/tracking'

export function useTracking() {
  const [events, setEvents] = useState<TrackingEvent[]>([])
  const { data: session, status } = useSession()

  const initTracking = () => {
    const client = amplitude.getInstance()
    client.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY)
  }

  const trackPageView = (pageView: string) => {
    // console.log(`trackPageView ${pageView}`)
    setEvents((prev) => [
      ...prev,
      { eventName: 'page_view', parameters: { page: pageView } },
    ])
  }

  const trackEvent = (event: TrackingEvent) => {
    setEvents((prev) => [...prev, event])
  }

  const updateUserProperties = (properties: Record<string, string>) => {
    const client = amplitude.getInstance()
    client.setUserProperties(properties)

    if (process.env.NEXT_PUBLIC_TRACKING_EVENTS_LOG) {
      console.log(`Event: (UpdateProperties): ${JSON.stringify(properties)}`)
    }
  }

  useEffect(() => {
    if (
      status === 'authenticated' &&
      session &&
      session.user &&
      session.user.email &&
      events.length > 0
    ) {
      setEvents([])

      const client = amplitude.getInstance()
      client.setUserId(session.user.email)
      events.forEach((event) => {
        const parameters = {
          ...event.parameters,
          opoName: session?.user?.opoName || 'unknown',
          platform: session?.user?.platform || 'unknown',
        }

        if (process.env.NEXT_PUBLIC_TRACKING_EVENTS_LOG) {
          console.log(
            `Event: "${event.eventName}", User: ${
              session?.user?.email
            }, Params: ${JSON.stringify(parameters)}`
          )
        }

        if (process.env.NEXT_PUBLIC_TRACKING_EVENTS_ENABLED) {
          client.logEvent(event.eventName, parameters)
        }
      })

      // client.logRevenue(PRICE, QTY, PRODUCT)
    }
  }, [events, session, status])

  return {
    initTracking,
    trackPageView,
    trackEvent,
    updateUserProperties,
  }
}
